.toolbar {
  display: flex;
  align-items: center;

  background-color: white;
  font-weight: 500;
  border-bottom: 1px solid #eeeef1;
  text-align: center;


  position: sticky;
  top: 0px;
}

@supports (backdrop-filter: blur(20px)) {
  .toolbar {
    border: none;
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(20px);
  }
}

.toolbar-title {
  margin: 0;
  font-size: 16px;
  font-weight: 800;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}