.messenger {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  /* height: 100vh; */
}

.container {
  padding: 10px;
}
.content {
  background: white;
}

.footer {
  grid-column-start: 2;
  background: white;
}